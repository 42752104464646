import React from 'react'
import {Typography} from '@mui/material';
import { styled } from "@mui/system";

const SocialCountText = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 300,
    marginBottom: '0px',
    marginTop: '0px',

}));


const SocialCount = (props) => {
    return <SocialCountText component="p" sx={{ color: (theme) => (theme.palette.secondary.main) }}>{props.children}</SocialCountText>;
  };


export default SocialCount