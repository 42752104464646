import React from 'react'
import {Typography} from '@mui/material';
import {Grid, Box, colors, Paper, Button, Link} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from "@mui/system";
import { NavLink as NavLinkBase  } from 'react-router-dom';
import AccountingBookkeepingMasterClass from '../../images/accounting-bookkeeping-master-class.jpg';
import PowerBiFinancialReportingFinancialAnalysis from '../../images/power-bi-financial-reporting-financial-analysis.jpg';
import AccountingAnalyticsFinancialBiMasterClassExcel from '../../images/accounting-analytics-financial-bi-master-class-excel.jpg';


const HomeCoursesHeading = styled(Typography)(({ theme }) => ({
    fontWeight: 300,
    marginBottom: '15px',
    marginTop: '15px',
    fontSize: '18px'
}));

const NavLink = styled((props) => (<NavLinkBase {...props} end={props.to === "/"} />
    ))(({ theme }) => ({    
        textDecoration: "none",
        color: 'white', 
        fontWeight: 300, 
        fontSize: theme.spacing(2),
        padding : theme.spacing(1, 4),  
        margin : theme.spacing(2),
        background: grey[700],
        borderRadius: 4, 
        "&.hover": {
        color: 'white',    
        background: grey[100],
        }
    }));




    

const HomeBlog = () => {

    const logoStyle = {
        width: '100%',
      };

    return(
        <>
        <Box>
           <Grid spacing={0} container sx={{p: 2, mt: 3, textAlign: 'left', }}>
              
                <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'center'}}>
                    <Typography component="h3" variant="h4" sx={{ fontWeight: 300, lineHeight: '1.5',mb: 0,}}>Courses</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} p={1} mb={2}>
                        <img style={logoStyle} src={AccountingBookkeepingMasterClass} alt="Accounting &  Bookkeeping MasterClass - Beginner to Advance" />
                        <HomeCoursesHeading component="h3" variant="h5">Accounting &  Bookkeeping MasterClass - Beginner to Advance</HomeCoursesHeading>
                        <Typography component="p" variant="p" sx={{ fontWeight: 300, fontSize: '14px', lineHeight: '1.5', mb: 1, mt: -1, color: (theme) => (theme.palette.primary.main) }}>Irfan Sharif ACA ACCA</Typography>
                        <Typography component="p" variant="p" sx={{ fontWeight: 300, fontSize: '12px', lineHeight: '1.5', mb: 1, mt: -1, color: (theme) => (theme.palette.greyColor.main) }}>16 total hours - 219 lectures - All Levels</Typography>
                        <Link target="_blank" rel="noopener" href="https://www.udemy.com/course/accounting-for-entrepreneurs/" underline="none" color={'secondary'} sx={{ fontWeight: 300, fontSize: '14px',}}>
                            See details
                        </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={4} p={1} mb={2}>
                    <img style={logoStyle} src={PowerBiFinancialReportingFinancialAnalysis} alt="Power BI Financial Reporting & Financial Analysis A to Z" />
                    <HomeCoursesHeading component="h3" variant="h5">Power BI Financial Reporting & Financial Analysis A to Z</HomeCoursesHeading>
                    <Typography component="p" variant="p" sx={{ fontWeight: 300, fontSize: '14px', lineHeight: '1.5', mb: 1, mt: -1, color: (theme) => (theme.palette.primary.main) }}>Irfan Sharif ACA ACCA</Typography>
                    <Typography component="p" variant="p" sx={{ fontWeight: 300, fontSize: '12px', lineHeight: '1.5', mb: 1, mt: -1, color: (theme) => (theme.palette.greyColor.main) }}>13 total hours - 108 lectures - All Levels</Typography>
                        <Link target="_blank" rel="noopener" href="https://www.udemy.com/course/power-bi-for-financial-reporting-and-financial-analysis/" underline="none" color={'secondary'} sx={{ fontWeight: 300, fontSize: '14px',}}>
                            See details
                        </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={4} p={1}  mb={2} >
                    <img style={logoStyle} src={AccountingAnalyticsFinancialBiMasterClassExcel} alt="Accounting Analytics & Financial BI Masterclass with Excel" />
                    <HomeCoursesHeading component="h3" variant="h5">Accounting Analytics & Financial BI Masterclass with Excel</HomeCoursesHeading>
                    <Typography component="p" variant="p" sx={{ fontWeight: 300, fontSize: '14px', lineHeight: '1.5', mb: 1, mt: -1, color: (theme) => (theme.palette.primary.main) }}>Irfan Sharif ACA ACCA</Typography>
                    <Typography component="p" variant="p" sx={{ fontWeight: 300, fontSize: '12px', lineHeight: '1.5', mb: 1, mt: -1, color: (theme) => (theme.palette.greyColor.main) }}>11 total hours - 94 lectures - Expert</Typography>
                        <Link  target="_blank" rel="noopener" href="https://www.udemy.com/course/financial-reporting-analysis-w-ms-excel-powerpivot-dax/" underline="none" color={'secondary'} sx={{ fontWeight: 300, fontSize: '14px',}}>
                            See details
                        </Link>
                </Grid>

                <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'center', mt: 4, mb: 4}}>
                    <NavLink to="/courses">See All</NavLink>
                </Grid>

            </Grid>
        </Box>
        </>
    )
}

export default HomeBlog