import React from 'react'
import { Outlet } from 'react-router-dom';
import {Box, styled} from '@mui/material';

const AppBody = styled(Box)(({ theme }) => ({
    // backgroundColor: grey[100],
    // padding : theme.spacing(1), 
  }));


const Content = () =>{
    return(
        <>
            <AppBody>
                <Outlet/>
            </AppBody>
        </>
    )
}

export default Content