
import React from 'react'

const Courses = () =>{
    return(
        <>
            coming soon
        </>
    )
}

export default Courses;