import ServicesMain from "./ServicesMain"
import {Grid, Typography} from '@mui/material';
import SocialCount from '../../components/SocialCount';
import ServicesDetails from "./ServicesDetails";

import { styled } from "@mui/system";


const BottomText = styled(Typography)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    fontSize: '30px',
    fontWeight: 300,
    textAlign: 'center',
    fontFamily: 'Brush Script MT',

    
    
}));


const Services = () =>{
    return(
        <>
            <ServicesMain></ServicesMain>
            <Grid container sx={{pb: 0, pt: 6}}>
                {/* <Grid item xs={12} sm={12} md={3} p={1} >
                    <SocialCount>15+ years of experiene</SocialCount>
                </Grid>
                <Grid item xs={12} sm={12} md={3} p={1} >
                    <SocialCount>35+ Accounting firms</SocialCount>
                    
                </Grid>
                <Grid item xs={12} sm={12} md={3} p={1} >
                    <SocialCount>8+ countries</SocialCount>
                </Grid> */}
            </Grid>
            <ServicesDetails></ServicesDetails>

            <Grid container sx={{pb: 0, pt: 0}}>
                <Grid item xs={12} sm={12} md={12}>
                    <BottomText sx={{ color: (theme) => (theme.palette.greyColor.main) }}>
                        We successfully automated the dataflow between various systems with remarkable proficiency. The ITAAI team not only demonstrates a deep technological understanding but also excel in navigating complex business processes
                    </BottomText>
                </Grid>
            </Grid>

        
        </>
    )
}

export default Services