import React from 'react'
import {Typography} from '@mui/material';
import { styled } from "@mui/system";

const MianHeading = styled(Typography)(({ theme }) => ({
    paddingTop: theme.spacing(8),
    fontSize: '50px',
    [theme.breakpoints.down('sm')]:{
        paddingTop: theme.spacing(0),
        fontSize: '40px',
        margintop: '0px',
    },
}));


const PageHeading = (props) => {
    return <MianHeading  variant='h2' component='h1'>{props.children}</MianHeading>;
  };


export default PageHeading