import React from 'react'
import {Typography} from '@mui/material';
import {Grid, Box, colors, Paper} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from "@mui/system";
import { NavLink as NavLinkBase  } from 'react-router-dom';
import FinancialReportingTools from '../../images/financial-reporting-tools.jpg';
import DevopsServices from '../../images/devops-services.jpg';
import AIDriven from '../../images/aI-driven.jpg';
import DataIntegrationAutomation from '../../images/data-integration-automation.jpg';
import MobileAppDevelopment from '../../images/mobile-app-development.jpg';
import ErpIntegration from '../../images/erp-integration.jpg';

const HomeServicesHeading = styled(Typography)(({ theme }) => ({
    fontWeight: 300,
    marginBottom: '15px',
    marginTop: '15px',    
}));


const NavLink = styled((props) => (
<NavLinkBase {...props} end={props.to === "/"} />
))(({ theme }) => ({    
    textDecoration: "none",
    color: 'white', 
    fontWeight: 300, 
    fontSize: theme.spacing(2),
    padding : theme.spacing(1, 4),  
    margin : theme.spacing(2),
    background: grey[700],
    borderRadius: 4, 
    "&.hover": {
    color: 'white',    
    background: grey[100],
    }
}));


const ServicesDetails = () => {

    const logoStyle = {
        width: '100%',
      };

    return(
        <>
        <Box>
           <Grid spacing={0} container sx={{p: 2, mt: 3, pb: 0, textAlign: 'center', backgroundColor: colors.grey[100]}}>
              
                <Grid item xs={12} sm={12} md={4} p={1} mb={4}>
                    <img style={logoStyle} src={DataIntegrationAutomation} alt="Data Integration  &  Automation" />
                    <HomeServicesHeading component="h3" variant="h5" sx={{ color: (theme) => (theme.palette.secondary.main) }}>Data Integration  &  Automation</HomeServicesHeading>
                    <Typography variant="p" sx={{ fontWeight: 300, lineHeight: '1.5'}}>
                    At ITAAI, we understand the critical role of data in accounting. Our team of experienced chartered accountants excel in integrating and automating data flows to enhance the efficiency of your financial processes. With a deep understanding of accounting principles, we ensure that your data is accurately synchronized and processed, reducing manual errors and saving you time.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={4} p={1} mb={4}>
                    <img style={logoStyle} src={FinancialReportingTools} alt="Financial Reporting Tools" />
                    <HomeServicesHeading component="h3" variant="h5" sx={{ color: (theme) => (theme.palette.secondary.main) }}>Financial Reporting Tools</HomeServicesHeading>
                    <Typography variant="p" sx={{ fontWeight: 300, lineHeight: '1.5'}}>
                        Empower your accounting firm or business organization with insightful financial reports and dashboards. Our team of chartered accountants combines their expertise with cutting-edge technology to provide you with customized financial reporting tools. We create solutions that help you make informed decisions, while ensuring compliance with accounting standards.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={4} p={1} mb={4}>
                    <img style={logoStyle} src={DevopsServices} alt="DevOps & DataOps Services" />
                    <HomeServicesHeading component="h3" variant="h5" sx={{ color: (theme) => (theme.palette.secondary.main) }}>DevOps & DataOps Services</HomeServicesHeading>
                    <Typography variant="p" sx={{ fontWeight: 300, lineHeight: '1.5'}}>
                        Efficient software development and data management are essential for accounting. At ITAAI, our team of chartered accountants and developers lead the way in DevOps and DataOps services. We seamlessly integrate technology into your accounting processes, ensuring data accuracy, security, and compliance. Our approach is tailored to the unique needs of accounting firms and businesses, optimizing their operations.
                    </Typography>
                </Grid>


                <Grid item xs={12} sm={12} md={4} p={1} mb={2}>
                    <img style={logoStyle} src={MobileAppDevelopment} alt=">Mobile App Development" />
                    <HomeServicesHeading component="h3" variant="h5" sx={{ color: (theme) => (theme.palette.secondary.main) }}>Mobile App Development</HomeServicesHeading>
                    <Typography variant="p" sx={{ fontWeight: 300, lineHeight: '1.5'}}>
                    Our team, led by chartered accountants, specializes in developing mobile applications tailored to the specific needs of the accounting industry. These apps provide secure, user-friendly interfaces for financial management and client interactions. We ensure that your mobile solutions align with accounting best practices and deliver a superior user experience.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={4} p={1} mb={2}>
                    <img style={logoStyle} src={ErpIntegration} alt="ERP Integration" />
                    <HomeServicesHeading component="h3" variant="h5" sx={{ color: (theme) => (theme.palette.secondary.main) }}>ERP Integration</HomeServicesHeading>
                    <Typography variant="p" sx={{ fontWeight: 300, lineHeight: '1.5'}}>
                    We understand that accounting is just one part of your overall business operations. Our expertise allows us to seamlessly integrate accounting solutions with your enterprise's broader operations. We ensure that your ERP systems are synchronized with your financial data for a holistic and efficient approach.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={4} p={1}  mb={2} >
                    <img style={logoStyle} src={AIDriven} alt="AI-driven Expense  Management" />
                    <HomeServicesHeading component="h3" variant="h5" sx={{ color: (theme) => (theme.palette.secondary.main) }}>AI-driven Expense  Management</HomeServicesHeading>
                    <Typography variant="p" sx={{ fontWeight: 300, lineHeight: '1.5'}}>
                        We offer AI-driven expense management solutions. Our approach combines accounting acumen with artificial intelligence to automate expense tracking and management. This ensures accurate and efficient management of expenses, helping you maintain financial control and compliance.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        </>
    )
}

export default ServicesDetails