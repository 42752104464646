import React from 'react'
import {Box} from '@mui/material';

const  Tutorials = () => {

    return(
        <>
        <Box>
            coming soon
        </Box>
        </>
    )
}

export default Tutorials