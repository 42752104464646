import React from 'react'
import {Box} from '@mui/material';
import { styled } from "@mui/system";
import { grey } from '@mui/material/colors';

const AppFooter = styled(Box)(({ theme }) => ({
    marginTop: 'auto',
    backgroundColor: grey[200],
    padding : theme.spacing(1, 1),  
    fontSize: '12px',   
    color:  grey[700],
  }));

const Footer = () => {
    return(
        <>
            <AppFooter>
                itaai.com 2023 All Rights Reserved
            </AppFooter>
        </>
    )
}

export default Footer