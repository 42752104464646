import React from 'react'
import {Box} from '@mui/material';


const Templates = () =>{

    return(
        <>
             <Box>
             coming soon
            </Box>
        </>
    )
}

export default Templates