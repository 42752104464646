import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      'service_o5by9rm', 
      'template_87lxs0k', 
      form.current, 
      'CEnxYNIbFBXTNLf3c')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <>

{/* <Box component="form" ref={form} onSubmit={sendEmail}
      sx={{'& > :not(style)': { m: 1, width: '25ch' },}}
      noValidate
      autoComplete="off"
    >
      <TextField  label="Name" variant="outlined" name='user_name' type='text'/>
      <TextField  label="Email" variant="outlined" name='user_email' type='email'/>
     
    </Box> */}

     <form ref={form} onSubmit={sendEmail}>
      
      <p>
        <label>Name</label>
      </p>
      <input type="text" name="user_name" autoComplete='off' />

      <p>
        <label>Email</label>
      </p>
      
      <input type="email" name="user_email" autoComplete='off'  required/>

      <p>
      <label>Message</label>
      </p>

      <textarea name="message" autoComplete='off' />
      <p>
      <input type="submit" value="Send" />
      </p>
      
    </form>
    </>
  );
};

export default Contact;