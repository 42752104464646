import React from 'react'
import HomeMainImg from './HomeMain';
import {Grid} from '@mui/material';
import SocialCount from '../../components/SocialCount';
import HomeServices from './HomeServices';
import HomeBlog from './HomeCourses';

const Home = () => {
    return(
        <>
            <HomeMainImg />
            <Grid container sx={{pb: 0, pt: 6}}>
                {/* <Grid item xs={12} sm={12} md={3} p={1} >
                    <SocialCount>15+ years of experiene</SocialCount>
                    <SocialCount>250k+ students</SocialCount> 
                </Grid>
                <Grid item xs={12} sm={12} md={3} p={1} >
                    <SocialCount>35+ Accounting firms</SocialCount>
                </Grid>
                <Grid item xs={12} sm={12} md={3} p={1} >
                    <SocialCount>250+ Business organizations</SocialCount>
                </Grid>
                <Grid item xs={12} sm={12} md={3} p={1} >
                    <SocialCount>100+ countries</SocialCount>
                </Grid> */}
            </Grid>
            <HomeServices/>
            {/* <HomeBlog/> */}
        </>
    )
}

export default Home