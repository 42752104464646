import React from 'react'
import { styled } from "@mui/system";
import {Box} from '@mui/material';
import homeimg from '../../images/home-img.png';
import PageHeading from '../../components/PageHeading';


import PageTop from '../../components/PageTop';

const HomeHeaderImg = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: "100%",
    height: "300px",
    zIndex: '-1',
    backgroundImage: `url(${homeimg})`,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
  }));



const HomeMainImg = () =>{
    return(
        <>
            <PageTop>
                <HomeHeaderImg />
                <PageHeading>
                        Unlocking Tomorrow's Finance <br />
                        where Technology Meets 
                        Accounting <br /> & Analytics Globally!
                </PageHeading>
           
            </PageTop>

        </>
    )
}

export default HomeMainImg