import './App.css';
import About from './pages/About';
import Home from './pages/Home/Home'
import Resources from './pages/Resources'
import Services from './pages/Services/Services'
import Courses from './pages/Courses'
import Contact from './pages/Contact/Contact'
import Templates from './pages/Templates'
import Error from './pages/Error'
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import SharedLayout from './pages/SharedLayout'
import Tutorials from './pages/Tutorials';

function App() {
  return (
    <BrowserRouter>
      <Routes>

        <Route path='/' element={<SharedLayout/>} >
        <Route index element={<Home/>} />
          <Route path='services' element={<Services/>} />
          <Route path='courses' element={<Courses/>} />
          <Route path='tutorials' element={<Tutorials/>} />
          <Route path='templates' element={<Templates/>} />
          <Route path='resources' element={<Resources/>} />
          <Route path='about' element={<About/>} />
          <Route path='contact' element={<Contact/>} />
          <Route path='*' element={<Error/>} />
        </Route>

        {/* <Route path='dashboard' element={<div>dashboard</div>} >
          <Route path='test1' element={<div>test1</div>} />
          <Route path='test2' element={<div>test2</div>} />
        </Route> */}

      </Routes>
    </BrowserRouter>
  );
}

export default App;