import React from 'react'
import {Box} from '@mui/material';
import { styled } from "@mui/system";

const HomeHeader = styled(Box)(({ theme }) => ({
    position: 'relative',
    zIndex: '1',
}));

const PageTop = (props) => {
    return <HomeHeader>{props.children}</HomeHeader>;
  };

export default PageTop