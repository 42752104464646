import React from 'react'
import {Box, AppBar, Toolbar} from '@mui/material';
import { NavLink as NavLinkBase  } from 'react-router-dom';
import { Link  } from 'react-router-dom';
import { styled } from "@mui/system";
import { grey } from '@mui/material/colors';
import itaai from '../images/itaai.jpg';


const NavLink = styled((props) => (
    <NavLinkBase {...props} end={props.to === "/"} />
    ))(({ theme }) => ({    
        textDecoration: "none",
        color:  grey[700],
        fontSize: theme.spacing(2),
        padding : theme.spacing(1, 1),  
        margin : theme.spacing('auto', 1),
        // background: grey[300],
        borderRadius: 4, 
        "&.active": {
        color: 'white',    
        background: grey[700],
        }
    }));


const Header = () =>{

    const logoStyle = {
        width: '100px',
      };

    return(
        <>
            <AppBar position='sticky' color='transparent' elevation={0} sx={{bgcolor: '#fff'}}>
              <Toolbar variant="regular" disableGutters d>
                    <Box component="div" sx={{ flexGrow: 1}}>
                        <Link to="/" ><img style={logoStyle} src={itaai} alt="itaai" /></Link>
                    </Box>
                    {/* <NavLink to="/courses">Courses</NavLink>  */}
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/services">Services</NavLink>
                    {/* <NavLink to="/tutorials">Tutorials</NavLink>
                    <NavLink to="/templates">Templates</NavLink>
                    <NavLink to="/resources">Resources</NavLink> */}
                    <NavLink to="/contact">Contact</NavLink>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header