import React from 'react'
import {Container} from '@mui/material';
import Header from '../components/Header'
import Content from '../components/Content';
import Footer from '../components/Footer';


    const SharedLayout = () =>{
        return(
            <>
                <Container maxWidth="lg" sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
                    <Header/>
                    <Content/>
                    <Footer/>
                </Container>
            </>
        )
}

export default SharedLayout