import {createTheme} from "@mui/material/";
import { grey } from '@mui/material/colors';

export const theme = createTheme ({

    palette: {
        primary: {
            main: "#298dcb",
            dark: "#25338b",
            light: "#3412ff",
        },
        secondary: {
            main: "#25338b",
        },
        otherColor:{
            main: "#ccd0d8",
            dark: "#1c0c7d",
            light: "#3412ff",
        },
        greyColor:{
            main: grey[800],
            dark: grey[900],
            light: grey[500],
        }
      }


    
})