import React from 'react'
import {Container, Box, AppBar, Toolbar, Button} from '@mui/material';


const About = () => {
    return(
        <>
            <Box>
            coming soon
            </Box>
        </>
    )
}

export default About