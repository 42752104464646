import React from 'react'
import { Link } from 'react-router-dom';
import {Container, Box, AppBar, Toolbar, Button} from '@mui/material';


const Error = () => {
    return(
        <>
        <Container maxWidth="lg">
            <Box>
            404
            <Link to="/">home   </Link>
            </Box>
        </Container>
        </>
    )
}

export default Error